import React, { useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import "./css/sectionThree.css";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import CountUp from "react-countup";
import AOS from "aos";
import "aos/dist/aos.css";
import "./css/sectionThree.css";

function SectionThird(props) {
  useEffect(() => {
    AOS.init();
  }, []);

  console.log(props.countStart);

  return (
    <Box>
      <Box className="desktop-view-section3">
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "2rem",
            color: "#1b8fd2",
            fontWeight: 600,
          }}
        >
          Our Strength
        </Typography>
        <Grid
          container
          sx={{
            padding: "5%",
          }}
        >
          <Grid item xs={6}>
            <img
              data-aos="zoom-in-down"
              data-aos-delay="80"
              data-aos-duration="3000"
              // src={require("./images/map_blue.png")}
              src="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1674474487940_map_blue.5c7d1d86aaed3e35b961.webp"
              style={{
                width: "70%",
                marginTop: "-11%",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              data-aos="fade-up"
              data-aos-delay="50"
              data-aos-duration="1000"
              sx={{
                fontSize: "1.6rem",
                fontWeight: 800,
              }}
              gutterBottom
            >
              We give complete control of your shipment and help you
            </Typography>

            <Grid
              container
              spacing={2}
              sx={{
                marginTop: "3%",
              }}
            >
              <Grid item xs={6}>
                <Card
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                >
                  <CardContent
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#1a8fd1",
                        fontSize: "2.6rem",
                        fontWeight: 700,
                      }}
                    >
                      <CountUp start={props.countStart} end={3000} />+
                    </Typography>
                    <Typography>Partners</Typography>
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    marginTop: "5%",
                  }}
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                >
                  <CardContent
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#1a8fd1",
                        fontSize: "2.6rem",
                        fontWeight: 700,
                      }}
                    >
                      <CountUp start={props.countStart} end={7000} />+
                    </Typography>
                    <Typography>Pincodes</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                >
                  <CardContent
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#1a8fd1",
                        fontSize: "2.6rem",
                        fontWeight: 700,
                      }}
                    >
                      <CountUp start={props.countStart} end={180} />+
                    </Typography>
                    <Typography>Tier 2 and Tier 3 Cities</Typography>
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    marginTop: "5%",
                  }}
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                >
                  <CardContent
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#1a8fd1",
                        fontSize: "2.6rem",
                        fontWeight: 700,
                      }}
                    >
                      <CountUp start={props.countStart} end={15000} />
                    </Typography>
                    <Typography>Shipments Per Day</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box className="mobile-view-section3">
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "1.8rem",
            fontWeight: 700,
            color: "#1b8fd2",
          }}
        >
          Our Strength
        </Typography>
        <Typography
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1000"
          sx={{
            fontSize: "1.3rem",
            padding: "5%",
            fontWeight: 800,
            textAlign: "center",
          }}
          gutterBottom
        >
          We give complete control of your shipment and help you
        </Typography>
        <Grid
          container
          sx={{
            padding: "5%",
          }}
        >
          <Grid item xs={12}>
            <img
              data-aos="zoom-in-down"
              data-aos-delay="80"
              data-aos-duration="3000"
              // src={require("./images/map_blue.png")}
              src="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1674474487940_map_blue.5c7d1d86aaed3e35b961.webp"
              style={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              sx={{
                marginTop: "3%",
              }}
            >
              <Grid item xs={6}>
                <Card
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                >
                  <CardContent
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#1a8fd1",
                        fontSize: "1.5rem",
                        fontWeight: 700,
                      }}
                    >
                      <CountUp start={props.countStart} end={3000} />+
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "0.8rem",
                      }}
                    >
                      Partners
                    </Typography>
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    marginTop: "5%",
                  }}
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                >
                  <CardContent
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#1a8fd1",
                        fontSize: "1.5rem",
                        fontWeight: 700,
                      }}
                    >
                      <CountUp start={props.countStart} end={7000} />+
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "0.8rem",
                      }}
                    >
                      Pincodes
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                >
                  <CardContent
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#1a8fd1",
                        fontSize: "1.5rem",
                        fontWeight: 700,
                      }}
                    >
                      <CountUp start={props.countStart} end={180} />+
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "0.668rem",
                      }}
                    >
                      Tier 2 and Tier 3 Cities
                    </Typography>
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    marginTop: "5%",
                  }}
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                >
                  <CardContent
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#1a8fd1",
                        fontSize: "1.5rem",
                        fontWeight: 700,
                      }}
                    >
                      <CountUp start={props.countStart} end={15000} />
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "0.8rem",
                      }}
                    >
                      Shipments Per Day
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default SectionThird;
